import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import I18nManager from './core/I18nManager/I18nManager';

const tmp_lang = sessionStorage.getItem('tmp_lang')
if (tmp_lang) {
  sessionStorage.removeItem('tmp_lang')
  if (tmp_lang === 'rtl' || tmp_lang === 'ltr') {
    I18nManager.setDirValue(tmp_lang === 'rtl' ? true : false)
    document.getElementsByTagName('html')[0].setAttribute("dir", tmp_lang);
    document.getElementsByTagName('html')[0].setAttribute("lang", tmp_lang === 'rtl' ? 'ar' : 'en');
    // remove bootstrap
    var element = document.getElementById(tmp_lang == 'ltr' ? "bootstrap-rtl" : "bootstrap-ltr");
    element.remove()
  } else {
    I18nManager.setDirValue(true)
    document.getElementsByTagName('html')[0].setAttribute("dir", 'ltr');
    document.getElementsByTagName('html')[0].setAttribute("lang", 'en');
  }
} else {
  if (window.location.pathname.startsWith('/ar/')) {
    I18nManager.setDirValue(true)
    document.getElementsByTagName('html')[0].setAttribute("dir", 'rtl');
    document.getElementsByTagName('html')[0].setAttribute("lang", 'ar');
    // remove bootstrap
    var element = document.getElementById("bootstrap-ltr");
    element.remove()
  } else {
    I18nManager.setDirValue(false)
    document.getElementsByTagName('html')[0].setAttribute("dir", 'ltr');
    document.getElementsByTagName('html')[0].setAttribute("lang", 'en');
    // remove bootstrap
    var element = document.getElementById("bootstrap-rtl");
    element.remove()
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Suspense fallback={<div></div>}>
    <App />
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
