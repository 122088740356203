import React from 'react'
import './TransitionButton.css'

const TransitionButton = ({ text,containerStyle, style, className, onClick }) => {
    return (
        <div className="transition-button" style={containerStyle}>
            <button style={style} href="/#" onClick={onClick} className={className}>{text}</button>
        </div>
    )
}

export default TransitionButton