import React from 'react'
import './ViewDisplay.css'

const ViewDisplay = ({ desktopView, mobileView, desktopClassName, mobileClassName }) => {
    return (
        <>
            <div className={"show-display-min-md hide-display-max-md " + desktopClassName}>
                {desktopView}
            </div>
            <div className={"hide-display-min-md show-display-max-md " + mobileClassName}>
                {mobileView}
            </div>
        </>
    )
}
export default ViewDisplay