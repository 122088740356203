import React from 'react'
import './DefaultButton.css'

const DefaultButton = ({ text, onClick, style, className }) => {
    return (
        <button style={style}
            className={"btn default-button " + className} onClick={onClick}>{text}</button>
    )
}

export default DefaultButton