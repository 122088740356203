import React, { Fragment } from 'react'
import Footer from '../footer/Footer'
import MainNavBar from '../navbar/MainNavBar'

const ViewContainer = ({ children }) => {
    return (
        <Fragment>
            <MainNavBar />
            {children}
            <Footer />
        </Fragment>
    )
}

export default ViewContainer