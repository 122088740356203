import React from 'react'
import ViewContainer from '../../core/routes/view-container/ViewContainer'
import { useNavigate } from 'react-router-dom';
import { redirectToPage } from '../../core/common-service/CommonService';
import I18nManager from '../../core/I18nManager/I18nManager';
import DefaultButton from '../_common/default-button/DefaultButton';
import HomeCurvedRectangle from '../home/home-top-section/home-curved-rectangle/HomeCurvedRectangle';
import images from '../../core/constants/images';
import ViewDisplay from '../_common/view-display/ViewDisplay';

const ComingSoon = ({ title }) => {
    let navigate = useNavigate()

    const goToPage = (i) => (e) => {
        e.preventDefault()
        redirectToPage(navigate, i)
    }

    const robot = (style) => {
        return <div>
            <HomeCurvedRectangle color="#3dc0c3" style={style} />
            <img className="img-fluid" src={images.homeTopSection.aiMl} alt="data" title="data" />
        </div>
    }

    return (
        <ViewContainer>
            <div className="container section-style">
                <div className="d-flex flex-column">
                    <div className="d-flex flex-column align-items-center" style={{ paddingBottom: 300 }}>
                        <h2 style={{ color: '#ffffff', fontSize: 16 }}>{title}</h2>
                        <h3 style={{ color: '#51E8EF', }}>{I18nManager.isRTL() ? "قريبا" : "Coming Soon"}...</h3>
                        <p style={{ color: '#ffffff', fontSize: 13 }}>{I18nManager.isRTL() ? "الصفحة التي كنت تبحث عنها سيتم نشرها قريبًا" : "The page you were looking for will be live soon"}</p>
                        <DefaultButton style={{ marginTop: 20, }} text={I18nManager.isRTL() ? "الصفحة الرئيسية" : "Go Home"} onClick={goToPage(0)} />
                        <div className="mt-5 mb-5">
                            <ViewDisplay
                                desktopView={robot({ left: "-25%", top: 300, maxHeight: 1000, maxWidth: 1000, })}
                                mobileView={robot({ left: "-35%", top: 100, maxHeight: 1000, maxWidth: 500, })}
                            />
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                        <span onClick={goToPage(15)} style={{ color: "#51E8EF" }}>Terms</span>
                        <span style={{ color: "#fff", marginLeft: 10, marginRight: 10 }}>|</span>
                        <span onClick={goToPage(16)} style={{ color: "#51E8EF" }}>Policy</span>
                    </div>
                </div>
            </div>
        </ViewContainer>
    )
}

export default ComingSoon