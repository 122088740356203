import React from 'react';
import ComingSoon from '../../components/coming-soon/ComingSoon';
const CareerAddJob = React.lazy(() => import('../../components/careers-center/careers-panel/career-add-job/CareerAddJob'));
const CareerPanelJobDetails = React.lazy(() => import('../../components/careers-center/careers-panel/career-panel-job-details/CareerPanelJobDetails'));
const CareersPanel = React.lazy(() => import('../../components/careers-center/careers-panel/CareersPanel'));
const TermsPolicy = React.lazy(() => import('../../components/terms-policy/TermsPolicy'));
const CookiesPolicy = React.lazy(() => import('../../components/cookies-policy/CookiesPolicy'));
const AiAcademyPage = React.lazy(() => import('../../components/home/home-top-section/ai-academy-page/AiAcademyPage'));
const TechnologyStackPage = React.lazy(() => import('../../components/home/home-top-section/technology-stack-page/TechnologyStackPage'));
const DataPage = React.lazy(() => import('../../components/home/home-top-section/data-page/DataPage'));
const IotPage = React.lazy(() => import('../../components/home/home-top-section/iot-page/IotPage'));
const RpaPage = React.lazy(() => import('../../components/home/home-top-section/rpa-page/RpaPage'));
const Support = React.lazy(() => import('../../components/support/Support'));
const NewSolutionDetails = React.lazy(() => import('../../components/solution-details/NewSolutionDetails'));
const Blog = React.lazy(() => import('../../components/blog/Blog'));
const BlogDetails = React.lazy(() => import('../../components/blog/blog-details/BlogDetails'));
const CareerApply = React.lazy(() => import('../../components/careers-center/career-apply/CareerApply'));
const CareersCenter = React.lazy(() => import('../../components/careers-center/CareersCenter'));
const ContactUs = React.lazy(() => import('../../components/contact-us/ContactUs'));
const DepartmentDetails = React.lazy(() => import('../../components/department-details/DepartmentDetails'));
const Home = React.lazy(() => import('../../components/home/Home'));

export const paths = [
    {
        index: 0,
        path: ["/", "/الصفحة-الرئسية", "/artificial-intelligence-home"],
        params: [],
        component: <Home />,
    },
    {
        index: 1,
        path: ["/تفاصيل-الخدمة", "/solution-details"],
        params: ["/:id", "/:uuid"],
        component: <NewSolutionDetails />,
    },
    {
        index: 2,
        path: ["/الوظائف", "/jobs"],
        params: [],
        component: <ComingSoon />,
    },
    {
        index: 3,
        path: ["/الوظيفة", "/job"],
        params: ["/:id", "/:uuid"],
        component: <CareerApply />,
    },
    {
        index: 4,
        path: ["/تفاصيل-القسم", "/department-details"],
        params: ["/:id", "/:uuid"],
        component: <DepartmentDetails />,
    },
    {
        index: 5,
        path: ["/اتصل-بنا", "/contact-us"],
        params: [],
        component: <ContactUs />,
    },
    {
        index: 6,
        path: ["/مقالات", "/blog"],
        params: [],
        component: <Blog />,
    },
    {
        index: 7,
        path: ["/تفاصيل-المدونة", "/blog-details"],
        params: ["/:uuid"],
        component: <BlogDetails />,
    },
    {
        index: 8,
        path: ["/تفاصيل-الخدمة", "/solution-details"],
        params: ["/:id", "/:uuid", "/:sub"],
        component: <NewSolutionDetails />,
    },
    {
        index: 9,
        path: ["/مساعدة", "/support"],
        params: [],
        component: <Support />,
    },
    {
        index: 10,
        path: ["/rpa", "/rpa"],
        params: [],
        component: <RpaPage />,
    },
    {
        index: 11,
        path: ["/iot", "/iot"],
        params: [],
        component: <IotPage />,
    },
    {
        index: 12,
        path: ["/data", "/data"],
        params: [],
        component: <DataPage />,
    },
    {
        index: 13,
        path: ["/technology-stack", "/technology-stack"],
        params: [],
        component: <TechnologyStackPage />,
    },
    {
        index: 14,
        path: ["/ai-academy", "/ai-academy"],
        params: [],
        component: <AiAcademyPage />,
    },
    {
        index: 15,
        path: ["/cookies-policy", "/cookies-policy"],
        params: [],
        component: <CookiesPolicy />,
    },
    {
        index: 16,
        path: ["/terms-policy", "/terms-policy"],
        params: [],
        component: <TermsPolicy />,
    },
    {
        index: 17,
        path: ["/careers-panel", "/careers-panel"],
        params: [],
        component: <CareersPanel />,
    },
    {
        index: 18,
        path: ["/careers-panel-job-details", "/careers-panel-job-details"],
        params: ["/:id"],
        component: <CareerPanelJobDetails />,
    },
    {
        index: 19,
        path: ["/careers-add-job", "/careers-add-job"],
        params: [],
        component: <CareerAddJob />,
    },
    {
        index: 20,
        path: ["/27A06A9E3D5E7F67EB604A39536208C9", "/27A06A9E3D5E7F67EB604A39536208C9"],
        params: [],
        component: <CareersCenter />,
    },
]