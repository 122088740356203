import React from 'react'

const HomeCurvedRectangle = ({ style, color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            style={{ position: "absolute", left: "-20%", top: 300, zIndex: -1, maxHeight: 700, maxWidth: 1000, ...style }}
            width="988.853" height="957.894" viewBox="0 0 988.853 957.894">
            <rect id="Rectangle_124" data-name="Rectangle 124" width="1002.333" height="375.064" rx="187.532" transform="translate(0 683.589) rotate(-43)" fill={color} />
        </svg>
    )
}
export default HomeCurvedRectangle