import React, { useEffect } from 'react'
import './App.css'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Route, BrowserRouter, useNavigate, useLocation, Routes } from 'react-router-dom';
import { paths } from './core/constants/route-paths';
import I18nManager from './core/I18nManager/I18nManager';
import { redirectToPage } from './core/common-service/CommonService';
import CookiesPopup from './components/_common/cookies-popup/CookiesPopup';
import ComingSoon from './components/coming-soon/ComingSoon';
const Home = React.lazy(() => import('./components/home/Home'));

const ScrollToTop = () => {
  let navigate = useNavigate()
  const { pathname } = useLocation();

  useEffect(() => {
    const hash = window.location.hash
    // check URL prefix
    if ((window.location.pathname.startsWith("/en") && I18nManager.isRTL()) ||
      (window.location.pathname.startsWith("/ar") && !I18nManager.isRTL())) {
      const path = window.location.pathname.split('/')
      let result = '/'
      path.forEach((element, i) => {
        result += (i > 1) ? (element + (i < path?.length - 1 ? '/' : '')) : ''
      });
      navigate(result)
      if (hash) {
        window.location.href = result.split('/').pop() + hash
      }
    }
  }, [navigate])

  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.location.pathname === '/') {
      // in case url does not start with basename
      redirectToPage(navigate, 0)
    }
    if (sessionStorage.getItem('anchor')) {
      setTimeout(() => {
        window.location.href = '#' + sessionStorage.getItem('anchor')
        sessionStorage.removeItem('anchor')
      }, 500);
    }
  }, [pathname]);

  return null;
}

const App = () => {

  useEffect(() => {
    if (window.location.pathname == "/") {
      window.location.href = (I18nManager.isRTL() ? "/ar" : "/en") + (I18nManager.isRTL() ? "/الصفحة-الرئسية" : "/artificial-intelligence-home")
    }
  }, [window.location.pathname])

  useEffect(() => {
    let lastUrl = window.location.href;
    new MutationObserver(() => {
      const url = window.location.href;
      if (url !== lastUrl) {
        lastUrl = url;
        // on change
        window.scrollTo(0, 0);
      }
    }).observe(document, { subtree: true, childList: true });
  }, [])
  return (
    <BrowserRouter basename={I18nManager.isRTL() ? "/ar" : "/en"}>
      <ScrollToTop />
      <CookiesPopup />
      <Routes>
        {paths.map((item, index) => (
          item.path.map((el) => (
            <Route key={index} path={el + (item.params.join(""))} element={item.component} />
          ))
        ))}
        <Route path="*" element={<Home />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App