import React, { Fragment, useState } from 'react'
import './MainNavBar.css'
import { useNavigate, useLocation } from 'react-router-dom';
import images from '../../constants/images'
import I18nManager from '../../I18nManager/I18nManager';
import { getRouteName, redirectToPage } from '../../common-service/CommonService';
import OffcanvasRightSidebar from './offcanvas-right-sidebar/OffcanvasRightSidebar';
import SearchInput from '../../../components/_common/search-input/SearchInput';
import TransitionButton from '../../../components/_common/transition-button/TransitionButton';
import ViewDisplay from '../../../components/_common/view-display/ViewDisplay';

const MainNavBar = () => {
    let navigate = useNavigate()
    let { pathname } = useLocation()

    const [navLinks, setNavLinks] = useState([
        {
            title: I18nManager.isRTL() ? "الرئيسية" : "Home", pathIndex: 0, active: false,
            data: [
                { title: I18nManager.isRTL() ? "الرئيسية" : "Home", optionIndex: 0, href: "/#", },
                { title: I18nManager.isRTL() ? "حضورنا" : "Our Presence", optionIndex: 0, href: "#our-presence", },
                { title: I18nManager.isRTL() ? "شركاؤنا" : "Partners", optionIndex: 0, href: "#our-partners", },
                { title: I18nManager.isRTL() ? "ما يميزنا" : "What makes us different", optionIndex: 0, href: "#what-makes-us-different", },
                { title: I18nManager.isRTL() ? "عنا" : "About us", optionIndex: 0, href: "#about-us", },
                { title: I18nManager.isRTL() ? "حلولنا المخصصة" : "Tailored solutions", optionIndex: 0, href: "#resilient-solutions", },
                // { title: I18nManager.isRTL() ? "حلول" : "Solutions", optionIndex: 0, href: "#solutions-services", },
            ]
        },
        {
            title: I18nManager.isRTL() ? "المصادر" : "Resources", pathIndex: 0, active: false,
            data: [
                { title: I18nManager.isRTL() ? "المقالات" : "Blog", pathIndex: 6, active: false, },
                { title: I18nManager.isRTL() ? "دراسات الحالة" : "Case studies", pathIndex: 9, active: false, },
                { title: I18nManager.isRTL() ? "التقارير" : "Analyst reports", pathIndex: 9, active: false, },
                { title: I18nManager.isRTL() ? "الندوات" : "Webinairs", pathIndex: 9, active: false, },
                { title: I18nManager.isRTL() ? "الفيديوهات" : "Demo videos", pathIndex: 9, active: false, },
            ]
        },
        // { title: I18nManager.isRTL() ? "الخدمات" : "Solutions", optionIndex: 0, href: "#solutions", active: false },
        // { title: I18nManager.isRTL() ? "القطاعات" : "Sectors", optionIndex: 0, href: "#sectors", active: false },
        // { title: I18nManager.isRTL() ? "الأقسام" : "Departments", optionIndex: 0, href: "#departments", active: false },
        // { title: I18nManager.isRTL() ? "من نحن" : "About Us", optionIndex: 0, href: "#about-us", active: false },
        { title: I18nManager.isRTL() ? "مركز الوظائف" : "Careers Center", pathIndex: 2, active: false },
        { title: I18nManager.isRTL() ? "الدعم" : "Support", pathIndex: 9, active: false },
        // { title: I18nManager.isRTL() ? "اتصل بنا" : "Contact us", pathIndex: 5, active: false },
    ])

    const onNavBarItemClick = (index) => (e) => {
        e.preventDefault()
        let tmpData = [...navLinks]
        tmpData.forEach((element, i) => {
            element.active = index == i
        });
        setNavLinks(tmpData)
        if (navLinks[index]?.href) {
            sessionStorage.setItem('anchor', navLinks[index]?.href.split('#').pop())
            redirectToPage(navigate, navLinks[index]?.optionIndex)
            if (window.location.pathname == "/en/artificial-intelligence-home" ||
                window.location.pathname == "/ar/%D8%A7%D9%84%D8%B5%D9%81%D8%AD%D8%A9-%D8%A7%D9%84%D8%B1%D8%A6%D8%B3%D9%8A%D8%A9") {
                window.location.href = '#' + sessionStorage.getItem('anchor')
                sessionStorage.removeItem('anchor')
            }
        }
        if (navLinks[index]?.pathIndex >= 0) {
            redirectToPage(navigate, navLinks[index]?.pathIndex)
        }
    }

    const onSubNavBarItemClick = (index, elIndex) => (e) => {
        e.preventDefault()
        if (navLinks[index].data[elIndex]?.href) {
            sessionStorage.setItem('anchor', navLinks[index].data[elIndex]?.href.split('#').pop())
            redirectToPage(navigate, navLinks[index].data[elIndex]?.optionIndex)
            if (window.location.pathname === "/en/artificial-intelligence-home" ||
                window.location.pathname === "/ar/%D8%A7%D9%84%D8%B5%D9%81%D8%AD%D8%A9-%D8%A7%D9%84%D8%B1%D8%A6%D8%B3%D9%8A%D8%A9") {
                if (sessionStorage.getItem('anchor')) {
                    window.location.href = '#' + sessionStorage.getItem('anchor')
                }
                // sessionStorage.removeItem('anchor')
            }
        }
        if (navLinks[index].data[elIndex]?.pathIndex >= 0) {
            redirectToPage(navigate, navLinks[index].data[elIndex]?.pathIndex)
        }
    }

    const goToPage = (i) => (e) => {
        e.preventDefault()
        redirectToPage(navigate, i)
    }

    const onChangeLanguage = (i) => (e) => {
        e.preventDefault()
        window.location.href = (i == 0 ? "/ar" : "/en") + pathname
        // I18nManager.setCookieDirValue()
    }

    return (
        <Fragment>
            <nav className="navbar sticky-top navbar-expand-md navbar-light">
                <div className="container-fluid container">
                    <a className="navbar-brand" href="/#" onClick={goToPage(0)}>
                        <img loading="lazy" className='img-fluid' src={images.logoColored} alt='ai elements logo' title='ai  elements logo' />
                    </a>
                    <div className="d-flex align-items-center">
                        <ViewDisplay
                            mobileView={
                                <div>
                                    <span style={{ color: I18nManager.isRTL() ? "#51E8EF" : "#fff" }} onClick={onChangeLanguage(0)}>AR</span>
                                    <span style={{ color: "#fff" }}>&nbsp;|&nbsp;</span>
                                    <span style={{ color: I18nManager.isRTL() ? "#fff" : "#51E8EF" }} onClick={onChangeLanguage(1)}>EN</span>
                                </div>
                            }
                        />
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </div>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ViewDisplay
                            desktopClassName="mx-auto"
                            desktopView={<SearchInput text={I18nManager.isRTL() ? "بحث" : "Search"} />}
                        />
                        <ul className="navbar-nav mb-2 mb-lg-0">
                            {navLinks.map((item, index) => (
                                <Fragment key={index + "li"}>
                                    {item?.data ?
                                        <li className="nav-item dropdown">
                                            <a className="nav-link" href="/#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                {item?.title}
                                            </a>
                                            <ul className="dropdown-menu dropdown-menu-start" aria-labelledby="navbarDropdown">
                                                {item?.data.map((el, i) => (
                                                    <Fragment key={index + "drop-1" + i}>
                                                        <li onClick={onSubNavBarItemClick(index, i)}>
                                                            <a href={getRouteName(el.pathIndex)} className="dropdown-item">{el.title}</a>
                                                        </li>
                                                        {el.isLanguage &&
                                                            <>
                                                                {el?.data.map((el1, i1) => (
                                                                    <li style={{ paddingLeft: 10, paddingRight: 10 }} key={i1 + "lang"} onClick={onChangeLanguage(i1)}>
                                                                        <a href="/#" className="dropdown-item">{el1.title}</a>
                                                                    </li>
                                                                ))}
                                                            </>
                                                        }
                                                    </Fragment>
                                                ))}
                                            </ul>
                                        </li>
                                        :
                                        <li key={index} className="nav-item" onClick={onNavBarItemClick(index)}>
                                            <a href={getRouteName(item.pathIndex)} className={"nav-link " + (item.active ? "active" : "")}>{item?.title}</a>
                                        </li>}
                                </Fragment>
                            ))}
                            <li className="nav-item d-flex align-items-center navbar-contact-us" onClick={goToPage(5)}>
                                <TransitionButton className="px-4" style={{ borderRadius: 4 }} text={I18nManager.isRTL() ? "تواصل معنا" : "Contact us"} />
                            </li>
                            <li style={{ listStyleType: "none" }} className="nav-item" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                                <div className="nav-link navbar-notification-icon">
                                    <i className="fas fa-bell"></i>
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link" href="/#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fas fa-globe"></i>
                                </a>
                                <ul className="dropdown-menu dropdown-menu-start" aria-labelledby="navbarDropdown">
                                    <li onClick={onChangeLanguage(0)}>
                                        <a href="/#" className="dropdown-item">AR</a>
                                    </li>
                                    <li onClick={onChangeLanguage(1)}>
                                        <a href="/#" className="dropdown-item">EN</a>
                                    </li>
                                </ul>
                            </li>
                            {/* <li className="nav-item" onClick={onChangeLanguage}>
                            <a href='/#' className="nav-link">{I18nManager.isRTL() ? "English" : "عربي"}</a>
                        </li> */}
                        </ul>
                    </div>
                </div>
            </nav>
            <OffcanvasRightSidebar />
            <ViewDisplay
                mobileView={
                    <>
                        <div className="floating-search-input-container" />
                        <div className="mx-3 floating-search-input">
                            <SearchInput text={I18nManager.isRTL() ? "بحث" : "Search"} />
                        </div>
                    </>
                }
            />
        </Fragment>
    )
}

export default MainNavBar