import React, { useEffect, Fragment, useState } from 'react'
import './CookiesPopup.css'
import { useNavigate } from 'react-router-dom'
import DefaultButton from '../default-button/DefaultButton'
import { getCookie, redirectToPage, setCookie } from '../../../core/common-service/CommonService'

const CookiesPopup = () => {
    let navigate = useNavigate()
    const [show, setShow] = useState(false)

    const goToPage = (i) => (e) => {
        e.preventDefault()
        redirectToPage(navigate, i)
    }

    const onAccept = () => {
        setCookie('cookies-set', 1)
        setShow(false)
    }

    useEffect(() => {
        const cookiesSet = getCookie('cookies-set')
        if (cookiesSet != 1) {
            setShow(true)
        }
    }, [])

    return (
        <Fragment>
            {show && <div className="cookies-popup">
                <div className="container sub-container">
                    <div className="row justify-content-center">
                        <div className="col-md-9 col-12 px-3">
                            <p>Cookies, including third-party cookies, are used on the website <a style={{ color: "#51E8EF" }} href="https://ai-elements.com" target="_blank" rel="noreferrer">www.ai-elements.com</a> (the "Website"). This policy will explain how cookies are set up, how they are used on this site, and how you may manage them.</p>
                            <p>We use cookies to give you a better browsing experience and services that are more tailored to your specific requirements and interests. Some cookies are required for you to log in, store your preferences, and for our website to function properly.</p>
                            <p>Other cookies provide us with useful information about how the Website is used, allowing us to improve its efficiency and accessibility.</p>
                        </div>
                        <div className="col-md-9 col-12 px-3">
                            <div className="d-flex flex-wrap justify-content-between">
                                <DefaultButton text="Accept and continue" className="mt-2" style={{ backgroundColor: "#000", }} onClick={onAccept} />
                                <DefaultButton text="View cookies settings" className="mt-2" style={{ backgroundColor: "transparent", border: "1px solid #fff" }}
                                    onClick={goToPage(15)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </Fragment>
    )
}

export default CookiesPopup