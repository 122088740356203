import React, { useEffect, useRef } from 'react'
import './SearchInput.css'

const SearchInput = ({ text, style }) => {

    const scrollTimeout = useRef(null)

    const onMouseEnter = () => {
        document.getElementsByClassName("floating-search-input-container")[0].classList.add("container-fade-in");
        document.getElementsByClassName("floating-search-input-container")[0].style.zIndex = "99"
        // const icon = document.getElementById("nav-search-icon-id")
        // icon.style.opacity = 0
    }

    const onMouseLeave = () => {
        document.getElementsByClassName("floating-search-input-container")[0].classList.remove("container-fade-in");
        document.getElementsByClassName("floating-search-input-container")[0].style.zIndex = "0"
        // const icon = document.getElementById("nav-search-icon-id")
        // icon.style.opacity = 1
    }

    useEffect(() => {
        window.onscroll = function () {
            clearTimeout(scrollTimeout.current)
            onMouseLeave()
            document.getElementsByClassName("floating-search-input")[0].style.display = "none"
            scrollTimeout.current = setTimeout(() => {
                document.getElementsByClassName("floating-search-input")[0].style.display = "unset"
                document.getElementsByClassName("floating-search-input")[0].classList.add("fade-in");
            }, 500);
        }
        return () => {
            clearTimeout(scrollTimeout.current)
        }
    }, [])


    return (
        <div style={style} className="form-inline mx-auto my-2 my-lg-0 navbar-search-container" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <i id="nav-search-icon-id" className="fas fa-search"></i>
            <input className="form-control" type="search" placeholder={text} aria-label={text} />
        </div>
    )
}

export default SearchInput