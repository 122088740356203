class I18nManager {
    constructor() {
        this.value = false;
    }

    setCookie(cname, cvalue) {
        const exdays = 30;
        var secure = '';
        if (window.location.protocol === 'https') {
            secure = "secure;";
        }
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + "; " + secure + expires + "; path=/";
    }

    setCookieDirValue(value) {
        this.setCookie('dir', value ? 'rtl' : 'ltr')
        window.location.reload()
    }

    setDirValue(value) {
        this.value = value
    }

    isRTL() {
        return this.value;
    }
}

export default new I18nManager();
