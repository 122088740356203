const images = {
    logoColored: require('../../assets/images/logo-colored.png'),
    visualyze: require('../../assets/images/visualyze.png'),
    mrYanalCeo: require('../../assets/images/mr-yanal-ceo.png'),
    ceoSignature: require('../../assets/images/ceo-signature.png'),
    iot: require('../../assets/images/iot.png'),
    homeTopSection: {
        aiAcademy: require('../../assets/images/home-top-section/ai-academy.png'),
        aiMl: require('../../assets/images/home-top-section/ai-ml.png'),
        data: require('../../assets/images/home-top-section/data.png'),
        iot: require('../../assets/images/home-top-section/iot.png'),
        rpa: require('../../assets/images/home-top-section/rpa.png'),
    },
    services: {
        agriculturalSmartSolutions: require('../../assets/images/services/agricultural-smart-solutions.png'),
        industries: require('../../assets/images/services/industries.png'),
        automation: require('../../assets/images/services/automation.png'),
        facialRecognition: require('../../assets/images/services/facial-recognition.png'),
        iot: require('../../assets/images/services/iot.png'),
        roboticProcessAutomation: require('../../assets/images/services/robotic-process-automation.png'),
        tracking: require('../../assets/images/services/tracking.png'),
        voiceRecognition: require('../../assets/images/services/voice-recognition.png'),
        thumbs: {
            agriculturalSmartSolutions: require('../../assets/images/services/thumbs/agricultural-smart-solutions.jpg'),
            industries: require('../../assets/images/services/thumbs/industries.jpg'),
            automation: require('../../assets/images/services/thumbs/automation.jpg'),
            facialRecognition: require('../../assets/images/services/thumbs/facial-recognition.jpg'),
            iot: require('../../assets/images/services/thumbs/iot.jpg'),
            roboticProcessAutomation: require('../../assets/images/services/thumbs/robotic-process-automation.jpg'),
            tracking: require('../../assets/images/services/thumbs/tracking.jpg'),
            voiceRecognition: require('../../assets/images/services/thumbs/voice-recognition.jpg'),
        }
    },
    departments: {
        creativity: require('../../assets/images/departments/creativity.png'),
        data: require('../../assets/images/departments/data.png'),
        inventing: require('../../assets/images/departments/inventing.png'),
        iot: require('../../assets/images/departments/iot.png'),
        thumbs: {
            creativity: require('../../assets/images/departments/thumbs/creativity.png'),
            data: require('../../assets/images/departments/thumbs/data.png'),
            inventing: require('../../assets/images/departments/thumbs/inventing.png'),
            iot: require('../../assets/images/departments/thumbs/iot.png'),
        }
    },
    sectors: {
        agriculture: require('../../assets/images/sectors/agriculture.png'),
        construction: require('../../assets/images/sectors/construction.png'),
        education: require('../../assets/images/sectors/education.png'),
        electronics: require('../../assets/images/sectors/electronics.png'),
        energy: require('../../assets/images/sectors/energy.png'),
        food: require('../../assets/images/sectors/food.png'),
        manufacturing: require('../../assets/images/sectors/manufacturing.png'),
        pharmaAndHealthcare: require('../../assets/images/sectors/pharma-and-healthcare.png'),
        technology: require('../../assets/images/sectors/technology.png'),
        telecommunications: require('../../assets/images/sectors/telecommunications.png'),
        transportation: require('../../assets/images/sectors/transportation.png'),
        thumbs: {
            agriculture: require('../../assets/images/sectors/thumbs/agriculture.png'),
            construction: require('../../assets/images/sectors/thumbs/construction.png'),
            education: require('../../assets/images/sectors/thumbs/education.png'),
            electronics: require('../../assets/images/sectors/thumbs/electronics.png'),
            energy: require('../../assets/images/sectors/thumbs/energy.png'),
            food: require('../../assets/images/sectors/thumbs/food.png'),
            manufacturing: require('../../assets/images/sectors/thumbs/manufacturing.png'),
            pharmaAndHealthcare: require('../../assets/images/sectors/thumbs/pharma-and-healthcare.png'),
            technology: require('../../assets/images/sectors/thumbs/technology.png'),
            telecommunications: require('../../assets/images/sectors/thumbs/telecommunications.png'),
            transportation: require('../../assets/images/sectors/thumbs/transportation.png'),
        }
    },
    partners: {
        alteryx: require('../../assets/images/partners/alteryx.png'),
        aws: require('../../assets/images/partners/aws.png'),
        cloudera: require('../../assets/images/partners/cloudera.png'),
        espressif: require('../../assets/images/partners/espressif.png'),
        googleCloud: require('../../assets/images/partners/google-cloud.png'),
        google: require('../../assets/images/partners/google.png'),
        ibm: require('../../assets/images/partners/ibm.png'),
        knx: require('../../assets/images/partners/knx.png'),
        microsoft: require('../../assets/images/partners/microsoft.png'),
        microstrategy: require('../../assets/images/partners/microstrategy.png'),
        nvidia: require('../../assets/images/partners/nvidia.png'),
        redHat: require('../../assets/images/partners/red-hat.png'),
        snowFlake: require('../../assets/images/partners/snow-flake.png'),
        softwareAg: require('../../assets/images/partners/software-ag.png'),
        talend: require('../../assets/images/partners/talend.png'),
        liferay: require('../../assets/images/partners/liferay.png'),
    },
    whatMakesUsDifferent: {
        costEffective: require('../../assets/images/what-makes-us-different/cost-effective.png'),
        researchAndDevelopment: require('../../assets/images/what-makes-us-different/research-and-development.png'),
        subjectMatterExperts: require('../../assets/images/what-makes-us-different/subject-matter-experts.png'),
        technologyIndependent: require('../../assets/images/what-makes-us-different/technology-independent.png'),
        wePartner: require('../../assets/images/what-makes-us-different/we-partner.png'),
        thumbs: {
            costEffective: require('../../assets/images/what-makes-us-different/thumbs/cost-effective.png'),
            researchAndDevelopment: require('../../assets/images/what-makes-us-different/thumbs/research-and-development.png'),
            subjectMatterExperts: require('../../assets/images/what-makes-us-different/thumbs/subject-matter-experts.png'),
            technologyIndependent: require('../../assets/images/what-makes-us-different/thumbs/technology-independent.png'),
            wePartner: require('../../assets/images/what-makes-us-different/thumbs/we-partner.png'),
        }
    },
    resilientSolutions: {
        assessmentModel: require('../../assets/images/resilient-solutions/assessment-model.png'),
        manDayModel: require('../../assets/images/resilient-solutions/man-day-model.png'),
        tokenModel: require('../../assets/images/resilient-solutions/token-model.png'),
        tailoredSolutions: require('../../assets/images/resilient-solutions/tailored-solutions.png'),
    },
    blog: {
        rpa: require('../../assets/images/blog/rpa.png'),
    }
}
export default images