import I18nManager from "../I18nManager/I18nManager";
import { paths } from "../constants/route-paths";

export function setCookie(cname, cvalue) {
    const exdays = 30;
    var secure = '';
    if (window.location.protocol === 'https') {
        secure = "secure;";
    }
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + secure + expires + "; path=/";
}

export function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return null;
}

export function deleteCookie(cname) {
    document.cookie = cname + "= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/"
}

export function deleteAllCookies() {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
    }
}

export const generateFakeId = (length = 32) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

export const toLowerCaseNoSpace = (val) => {
    return val?.toLowerCase().replace(/\s/g, '-')?.replace('/', '-')
}

export const getRouteName = (i) => {
    return paths[i]?.path[I18nManager.isRTL() ? (paths[i]?.path?.length - 2) : (paths[i]?.path?.length - 1)]
}

export const redirectToPage = (navigate, i, params = []) => {
    if (((I18nManager.isRTL() && window.location.pathname) !== ('/ar' + paths[i].path[paths[i].path.length - 2] + (params.join("")))) &&
        ((!I18nManager.isRTL() && window.location.pathname) !== ('/en' + paths[i].path[paths[i].path.length - 1] + (params.join(""))))) {
        // this to suppor tarabic URL
        // navigate(paths[i]?.path[I18nManager.isRTL() ? (paths[i].path.length - 2) : (paths[i].path.length - 1)] + (params.join("")))
        navigate(paths[i]?.path[I18nManager.isRTL() ? (paths[i].path.length - 1) : (paths[i].path.length - 1)] + (params.join("")))
    }
}