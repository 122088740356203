import React from 'react'
import I18nManager from '../../../I18nManager/I18nManager'
import './OffcanvasRightSidebar.css'

const OffcanvasRightSidebar = () => {
    return (
        <div className="offcanvas offcanvas-end offcanvas-notifications" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
            <div className="offcanvas-header">
                <h5 style={{ color: "#fff" }} id="offcanvasRightLabel">
                    <i style={{ color: "#51E8EF" }} className="fas fa-bell"></i>
                    &nbsp;&nbsp;Notifications
                </h5>
                <div className="d-flex align-items-center" data-bs-dismiss="offcanvas" aria-label="Close">
                    <span className="back-button-text">BACK</span>
                    <div className="back-button-container">
                        <i className={"fas fa-chevron-" + (I18nManager.isRTL() ? "left" : "right")}></i>
                    </div>
                </div>
            </div>
            <div className="offcanvas-body">
                <div className="item-container" style={{ borderBottom: 0 }}>
                    <h5 className="title">Coming Soon</h5>
                </div>
                {/* {[0, 0, 0, 0].map((item, index) => (
                    <div key={index} className="item-container">
                        <div className="d-flex">
                            <h5 className="title">Blog:&nbsp;</h5><h5 style={{ color: "#fff" }}>Topic Title</h5>
                        </div>
                        <p className="description">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero.</p>
                    </div>
                ))} */}
            </div>
        </div>
    )
}
export default OffcanvasRightSidebar