import React from 'react'
import './Footer.css'
import { useNavigate, useLocation } from 'react-router-dom';
import { redirectToPage } from '../../common-service/CommonService';
import I18nManager from '../../I18nManager/I18nManager';
import TransitionButton from '../../../components/_common/transition-button/TransitionButton';
import ViewDisplay from '../../../components/_common/view-display/ViewDisplay';

const Footer = () => {
    let navigate = useNavigate()
    let { pathname } = useLocation()

    const goToPage = (i) => (e) => {
        e.preventDefault()
        redirectToPage(navigate, i)
    }

    const onChangeLanguage = (i) => (e) => {
        e.preventDefault()
        window.location.href = (i == 0 ? "/ar" : "/en") + pathname
        // I18nManager.setCookieDirValue()
    }

    return (
        <footer className="footer mt-auto py-3 position-relative">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-sm-12 mt-4">
                        {!I18nManager.isRTL() && <span className="footer-title">
                            AiElements <span className="footer-description">was founded in Dec, 2020 as a company that specializes in delivering expert services ranging from developing to executing AI applications and solutions that are all driven by Data. We assist you in validating your data, structuring it and then sending it to AI.</span>
                        </span>}
                        {I18nManager.isRTL() &&
                            <span className="footer-description">تأسست<span className="footer-title"> AiElements </span> 'في ديسمبر عام 2020 كشركة متخصصة في علم البيانات والذكاء الاصطناعي تعنى بتقديم خدمات تتراوح من بناء إلى تنفيذ تطبيقات وحلول الذكاء الاصطناعي التي تعتمد جميعها على البيانات، لذا نحن نساعد في التحقق من مدى صحة البيانات المستخدمة، وتنظيمها، ثم إرسالها إلى الذكاء الاصطناعي.</span>}
                    </div>
                    <div className="col-md-2 col-sm-12">
                        <h3 className="mt-4 mb-4">{I18nManager.isRTL() ? "للتواصل معنا" : "Contact"}</h3>
                        <span className="footer-info">{I18nManager.isRTL() ? "هاتف" : "Phone"}:</span>&nbsp;
                        <a href='tel:962795981562'>
                            <span className="footer-info">{I18nManager.isRTL() ? "1562 9598 7 (962+)" : "(+962) 7 9598 1562"}</span>
                        </a>
                        <br />
                        <span className="footer-info">{I18nManager.isRTL() ? "بريدنا الالكتروني" : "Email"}:</span>&nbsp;
                        <a href='mailto:sales@ai-elements.com'>
                            <span className="footer-info">sales@ai-elements.com</span>
                        </a>
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <h3 className="mt-4 mb-4">{I18nManager.isRTL() ? "العنوان" : "Address"}</h3>
                        <span className="footer-info">{I18nManager.isRTL() ? "الأردن: عمان، شارع مكة، مجمع رقم 164" : "Mecca St. 164, Amman - Jordan"}</span><br />
                        <span className="footer-info">{I18nManager.isRTL() ? "السعودية: الرياض، شارع عثمان بن عفان، رقم 8484" : "8484 Uthman Ibn Affan Rd, Riyadh - Saudi Arabia"}</span><br />
                    </div>
                    <div className="col-md-3 col-12 mt-4">
                        <ViewDisplay
                            desktopView={
                                <div className="float-contact-us d-flex flex-wrap align-items-center justify-content-end">
                                    <a className="mb-2" href="https://twitter.com/AiElements/" target="_blank" rel="noreferrer"><i className="fab fa-twitter"></i></a>
                                    <a className="ms-2 mb-2" href="https://www.linkedin.com/company/aielements/" target="_blank" rel="noreferrer"><i className="fab fa-linkedin-in"></i></a>
                                    <a className="ms-2 mb-2" href="https://www.facebook.com/AiElements/" target="_blank" rel="noreferrer"><i className="fab fa-facebook-f"></i></a>
                                    <a className="ms-2 mb-2" href="https://www.instagram.com/AiElements/" target="_blank" rel="noreferrer"><i className="fab fa-instagram"></i></a>
                                    <a className="ms-2 mb-2" href="http://be.net/Aielements" target="_blank" rel="noreferrer"><i className="fab fa-behance"></i></a>
                                    <TransitionButton className="ms-2 mb-2" text={I18nManager.isRTL() ? "تواصل معنا" : "Contact Us"} onClick={goToPage(5)} />
                                </div>
                            }
                            mobileView={
                                <div className="float-contact-us d-flex flex-column align-items-center justify-content-center">
                                    <TransitionButton className="ms-2 mb-2 mt-5" text={I18nManager.isRTL() ? "تواصل معنا" : "Contact Us"} onClick={goToPage(5)} />
                                    <div className="d-flex mt-2 mb-5">
                                        <a className="mb-2" href="https://twitter.com/AiElements/" target="_blank" rel="noreferrer"><i className="fab fa-twitter"></i></a>
                                        <a className="ms-2 mb-2" href="https://www.linkedin.com/company/aielements/" target="_blank" rel="noreferrer"><i className="fab fa-linkedin-in"></i></a>
                                        <a className="ms-2 mb-2" href="https://www.facebook.com/AiElements/" target="_blank" rel="noreferrer"><i className="fab fa-facebook-f"></i></a>
                                        <a className="ms-2 mb-2" href="https://www.instagram.com/AiElements/" target="_blank" rel="noreferrer"><i className="fab fa-instagram"></i></a>
                                        <a className="ms-2 mb-2" href="http://be.net/Aielements" target="_blank" rel="noreferrer"><i className="fab fa-behance"></i></a>
                                    </div>
                                </div>
                            }
                        />

                        <div className="copyright-container mt-2">
                            <span className="footer-description">{I18nManager.isRTL() ? "حقوق النشر © 2021 ai-elements.com جميع الحقوق محفوظة" : "Copyright © 2021 ai-elements.com All rights reserved"}</span>
                            <div>
                                <span onClick={goToPage(15)} className="footer-title me-2">{I18nManager.isRTL() ? "اتفاقية ملفات تعريف الارتباط" : "Cookies Policy"}</span>
                                <span style={{ color: "#fff" }}>|</span>
                                <span className="footer-title ms-2">{I18nManager.isRTL() ? "سياسة الخصوصة" : "Privacy Policy"}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer >
    )
}

export default Footer